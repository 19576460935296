import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MetadataModule } from '@streamamg/amg-common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { SharedModule } from './core/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAuthModule } from './modules/user-auth/user-auth.module';
import { NavLinksComponent } from './core/components/nav-links/nav-links.component';
import { CookieBannerComponent } from './core/components/cookie-banner/cookie-banner.component';
import { StreamPlayModule } from '@streamamg/stream-play-lib/src/lib/stream-play.module';
import { StreamPlayLiveModule } from '@streamamg/stream-play-lib/src/lib/live-service/stream-play-live.module';
import { FooterSponsorsModule } from './modules/footer-sponsors/footer-sponsors.module';
import { CloudPayModule } from '@streamamg/cloud-pay-lib/src/public-api';
import { CloudMatrixModule } from '@streamamg/cloud-matrix-lib';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavLinksComponent,
    CookieBannerComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    UserAuthModule,
    StreamPlayModule,
    StreamPlayLiveModule,
    FooterSponsorsModule,
    MetadataModule,
    CloudMatrixModule,
    CloudPayModule.forRoot(
      {
        url: environment.cloudPayPath,
        language: 'en',
        isSso: true,
      }),
    StreamPlayLiveModule.forRoot(
      {
        checkStartDate: true,
        checkEndDate: true,
        offset: 60,
      },
    ),

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
