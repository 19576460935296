import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { WpCommonData } from '../../types/wordpress/common-data.type';
import { SubSink } from 'subsink';
import { WordpressService } from '../../services/wordpress.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieBannerComponent implements OnInit, OnDestroy {

  private subSink: SubSink;
  public commonData: WpCommonData;
  public showBanner: boolean;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    this.subSink = new SubSink();
    this.showBanner = false;
  }

  ngOnInit(): void {


    this.subSink.add(this.wordpressService.getCommonData<WpCommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;


          if (!window.localStorage?.getItem('cookie-banner-closed')) {
            this.showBanner = true;
          }

          this.cd.markForCheck();
        }
      })
    );
  }


  public closeBanner() {

    window.localStorage.setItem('cookie-banner-closed', '1');

    this.showBanner = false;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }

}
