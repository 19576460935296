import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@streamamg/components-lib/src/lib/image/image.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DateFormattedPipe } from '@amg-fe-angular/pipes/date-formatted.pipe';
import { IsExternalLinkPipe } from '@amg-fe-angular/pipes/is-external-link.pipe';
import { LabelPipe } from './pipes/label/label.pipe';
import { SafeModule } from '@streamamg/amg-common/src/lib/pipes/safe/safe.module';
import { LoaderModule } from '@streamamg/components-lib';

@NgModule({
  declarations: [
    IsExternalLinkPipe,
    DateFormattedPipe,
    LabelPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    LoaderModule,
    ImageModule,
    SafeModule,
  ],
  exports: [
    IsExternalLinkPipe,
    DateFormattedPipe,
    LabelPipe,
    LazyLoadImageModule,
    LoaderModule,
    ImageModule,
    SafeModule,
  ]
})
export class SharedModule {
}
