import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router, RouterEvent } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MetadataService, UrlUtils } from '@streamamg/amg-common';
import { CssVarsUtils } from '@amg-fe-angular/utils/css-vars-utils';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { WordpressService } from './core/services/wordpress.service';
import { WpCommonData } from './core/types/wordpress/common-data.type';
import { environment } from '../environments/environment';
import { configs } from './core/configs';
import { WpCompetitionMap } from './core/types/wordpress/competition.type';
import { UserAuthMyHoHService } from './modules/user-auth/user-auth-myhoh.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.has-top-banner') hasTopBanner = false;
  public appReady: boolean;
  private subSink: SubSink;
  public wpCommonData: WpCommonData;

  constructor(
    private userAuthMyHoHService: UserAuthMyHoHService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private wordpressService: WordpressService,
    private metaService: MetadataService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    // private longitudeService: LongitudeService,
  ) {
    this.subSink = new SubSink();
    this.appReady = false;
  }

  ngOnInit() {
    this.setCompetitionsStyle();
    CssVarsUtils.initScreenSizesOnRoot();
    this.onRouteChange();


    // retrieve Common data
    this.subSink.add(this.wordpressService
      .getCommonData<WpCommonData>()
      .subscribe((wpCommonData: WpCommonData) => {
        if (wpCommonData) {

          this.wpCommonData = wpCommonData;

          this.hasTopBanner = !!this.wpCommonData.longitude?.block_id;

          this.metaService.setDefaultMetadata({
            ...(wpCommonData.meta_tags || {}),
            ...(wpCommonData.twitter_card_tags || {}),
          });

          this.appReady = true;
          this.cd.markForCheck();


        }
      }),
    );
  }

  private onRouteChange() {
    let currentRoute = '';
    this.subSink.add(this.router.events
        .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
        .subscribe((event: NavigationStart | NavigationEnd) => {

          if (event instanceof NavigationStart) {

            this.renderer.addClass(this.document.body.parentElement, 'start-navigation');

            if (currentRoute === event.url.split('/')[1]) {
              this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');
            } else {
              this.renderer.removeClass(this.document.body.parentElement, 'same-page-navigation');
            }
          } else {

            // this.longitudeService.loadScript();

            setTimeout(() => {
              this.renderer.removeClass(this.document.body.parentElement, 'start-navigation');
            }, 1);

            if (currentRoute !== event.url.split('/')[1] && !event.url.startsWith('/handball-competitions-schedules')) {
              // if the new page is different of the previous, scroll to top
              window.scrollTo({top: 0});
            }

            this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');

            currentRoute = event.urlAfterRedirects.split('/')[1];
            this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
            this.sendGoogleAnalyticsData(event);

            this.setPixelTrackCode(event.url);
          }
        }),
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).includes('category') || Object.keys(params).includes('club') || Object.keys(params).includes('competition')) {
          let queryParams: Params = {
            ...params,
          };
          const category = params?.category;
          const club = params?.club;
          const competition = params?.competition;
          if (category) {
            queryParams = {
              ...queryParams,
              categories: category,
            };
            delete queryParams.category;
          }
          if (club) {
            queryParams = {
              ...queryParams,
              teams: club,
            };
            delete queryParams.club;
          }

          if (competition) {
            queryParams = {
              ...queryParams,
              competitions: competition,
            };
            delete queryParams.competition;
          }

          this.router.navigate([], {
            queryParams,
          });
        }
      }));
  }

  private setPixelTrackCode(currentUrl: string): void {

    if (environment.env === 'prod') {
      const pages = [
        '/home',
        '/hand-ball-competitions/ehf-champions-league-men',
        '/hand-ball-competitions/ehf-champions-league-women',
      ];

      document.querySelectorAll('script[src^="https://track.adform.net/"]').forEach((e) => {

        e.parentNode.removeChild(e);
        // console.log(e);
      });

      if (pages.find((path) => currentUrl.startsWith(path))) {
        // @ts-ignore
        window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
        // @ts-ignore
        window._adftrack.push({
          pm: 2149414,
        });

        // tslint:disable-next-line:only-arrow-functions
        (() => {
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://track.adform.net/serving/scripts/trackpoint/async/';
          const x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        })();
      }
    }
  }

  private sendGoogleAnalyticsData(event: NavigationEnd): void {


    if ((window as any).gtag && environment.google.code1) {
      (window as any).gtag('config', environment.google.code1, {
        page_path: event.urlAfterRedirects,
      });
    }

    const params = UrlUtils.extractUrlParamsFromExistingUrl(event.url);

    if (params.registered && params.subscriptionplanid) {

      (window as any).gtag('event', 'conversion', {send_to: `${environment.google.code2}/KXvHCOSYltgBEPrq-LgC`});
    }

  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  private setCompetitionsStyle() {

    this.subSink.add(this.wordpressService.getPageData(configs.wordpress.competition)
      .subscribe((competitions: WpCompetitionMap) => {
        if (competitions && Object.keys(competitions).length) {

          const styles: string[] = [];

          Object.keys(competitions).forEach((key) => {

            const competition = competitions[key];

            if (competition.color) {

              styles.push(`
                 [data-competition='${key}'].competition-color-txt,
                 [data-competition='${key}'] .competition-color-txt,
                 html [data-competition='${key}'] .competition-color-txt
                 {
                   color: ${competition.color} !important;
                 }

                 [data-competition='${key}'].competition-color-bg,
                 [data-competition='${key}'] .competition-color-bg,
                 [data-competition='${key}'] .competition-color-bg-before:before,
                 [data-competition='${key}'] .competition-color-bg-after:after,
                 [data-competition='${key}'].competition-color-bg-before:before,
                 [data-competition='${key}'].competition-color-bg-after:after,
                 html [data-competition='${key}'] .competition-color-bg {
                   background-color: ${competition.color} !important;
                 }

                 [data-competition='${key}'].competition-color-border,
                 [data-competition='${key}'] .competition-color-border {
                    border-color: ${competition.color} !important;
                  }
            `);
            }
          });

          const style = document.createElement('style');
          style.innerHTML = styles.join('');
          this.document.getElementsByTagName('head')[0].appendChild(style);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }
}
