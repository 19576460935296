import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UrlUtils } from '@streamamg/amg-common';
import { SubSink } from 'subsink';
import { filter } from 'rxjs/operators';
import { WpCommonData } from '../../types/wordpress/common-data.type';
import { WordpressService } from '../../services/wordpress.service';
import { configs } from '../../../core/configs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  private subSink: SubSink;
  public commonData: WpCommonData;
  public showSponsors: boolean;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {


    this.subSink = new SubSink();
    this.showSponsors = false;
  }

  ngOnInit(): void {

    this.subSink.add(this.wordpressService.getCommonData<WpCommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      })
    );

    this.setSponsors(this.router.url);
    this.subSink.add(this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {


        this.setSponsors(event.url);
      })
    );
  }

  private setSponsors(url: string): void {

    this.showSponsors = !url.startsWith('/hand-ball-competitions/') && !url.startsWith(configs.wordpress.campaign);
    this.cd.markForCheck();
  }


  public social(link): string {
    return UrlUtils.generateClassNameFromDomainName(link);
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
