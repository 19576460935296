<amg-loader *ngIf="!appReady"></amg-loader>

<div *ngIf="wpCommonData?.longitude?.block_id"
     [id]="wpCommonData.longitude.block_id"
     class="longitude-banner longitude-banner-top"></div>

<app-header *ngIf="appReady"></app-header>

<div id="page-container">
  <router-outlet *ngIf="appReady"></router-outlet>
</div>
<ng-container *ngIf="appReady">
  <app-footer></app-footer>
  <app-cookie-banner></app-cookie-banner>
</ng-container>
