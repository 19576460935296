// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env: 'dev',
  production: false,

  streamPlay: {
    baseUrl: 'https://staging.api.streamplay.streamamg.com/fixtures/handball/p',
  },
  google: {
    code1: null,
    code2: null
  },
  wordpress: {
    api: 'https://wp-devehf.streamamg.com/wp-json/wpa/v1'
  },
  sso: {
    clientId: 'b6282232d1094e97818e0f4ad75fdf36',
    clientSecret: '97aa535b-73eb-4c66-9c5a-5b605de0836d',
  },
  myHoH: {
    baseUrl: 'https://myhoh.feelgooddev.kraftwerk.co.at'
  },
  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devehf.streamamg.com',
  cloudPayPath: 'https://stagingehfpayments.streamamg.com',
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
