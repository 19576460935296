import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {WpFooterSponsors} from '../../core/types/wordpress/footer-sponsors.type';

@Component({
  selector: 'app-footer-sponsors',
  templateUrl: './footer-sponsors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterSponsorsComponent implements OnInit {

  @Input() footerSponsors: WpFooterSponsors[];

  constructor() { }

  ngOnInit(): void {
  }

}
