import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CloudPayService } from '@streamamg/cloud-pay-lib/src/lib/cloud-pay.service';
import { Observable, ReplaySubject } from 'rxjs';
import { configs } from '../../core/configs';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAuthMyHoHService {
  private readonly localStorageTokenKey = 'EHF_JWT_TOKEN';
  private readonly localStorageLoginPath = 'EHF_LOGIN_PATH';
  private _logoutPath = '/logout';

  private _oicsClientId = environment.sso.clientId;
  private _oicsClientSecret = environment.sso.clientSecret;
  private _oicsBaseUrl = configs.sso.urls.baseUrl;
  private _oicsAuthorizeUrl: string;
  private _oicsRedirectUrl: string;
  private _oicsPostLogoutUrl: string;

  // getter for the authorization URL, used by the login button
  public get oicsAuthorizeUrl(): string {
    return this._oicsAuthorizeUrl;
  }

  constructor(
    private router: Router,
    private location: Location,
    private httpClient: HttpClient,
    private cloudPayService: CloudPayService
  ) {
    this.setUrls();

    const jwtToken = this.getToken();
    if (jwtToken) {
      this.checkTokenExpiry(jwtToken);
    }

    this.checkRoute();
    this.cloudPayService.loadCloudPayScript();

    // remove the code query parameter from the URL and restore location
    let href = localStorage.getItem(this.localStorageLoginPath) || '';
    if (href.length > 0) {
      href = href.replace(window.location.origin, '');
      this.router.navigateByUrl(href);
      localStorage.removeItem(this.localStorageLoginPath);
    }
  }

  private setUrls() {
    // set the login and logout redirect URLs using the current host name
    this._oicsRedirectUrl = window.location.origin;
    this._oicsPostLogoutUrl = this._oicsRedirectUrl + this._logoutPath;

    // set the OICS authorization URL
    let authorizeUrl = new URL(`${this._oicsBaseUrl}/oauth2/v1/authorize`);
    authorizeUrl.searchParams.set('client_id', this._oicsClientId);
    authorizeUrl.searchParams.set('response_type', 'code');
    authorizeUrl.searchParams.set('redirect_uri', this._oicsRedirectUrl);
    authorizeUrl.searchParams.set('scope', 'openid profile email');
    this._oicsAuthorizeUrl = authorizeUrl.href;
  }

  private checkTokenExpiry(jwtToken: string): void {
    const jwtData = JSON.parse(atob(jwtToken.split('.')[1]));
    const isTokenValid = Date.now() < (jwtData.exp * 1000);

    if (!isTokenValid) {
      this.myHoHLogout();
    }
  }

  private checkRoute(): void {
    // call logout when redirected from OICS
    if (this.location.path() === this._logoutPath) {
      this.logout();
    }

    const url = new URLSearchParams(window.location.search);
    if (url.has('code')) {
      this.consumeAuthorizationCode(url.get('code'));
    }
  }

  private consumeAuthorizationCode(code: string): void {
    if (code) {
      // get the JWT token and log the user in using the authorization code
      this.getTokenUsingAuthCode(code)
      .subscribe(token => {
        localStorage.setItem(this.localStorageTokenKey, token);

        this.cloudPayService.loginJWT(token, null, false)
        .subscribe((loginResponse) => {
          console.log('logged in to CloudPay', loginResponse);
        }, (err) => {
          console.error('CloudPay login error', err);
        });
      });
    }
  }

  private getTokenUsingAuthCode(authorizationCode: string): Observable<any> {
    const response: ReplaySubject<any> = new ReplaySubject(1);
    const oicsTokenUrl = `${this._oicsBaseUrl}/oauth2/v1/token`;

    // send basic authorization header as base64 'clientId:clientSecret'
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Authorization': 'Basic ' + btoa(this._oicsClientId + ':' + this._oicsClientSecret)
      })
    };
    // send request parameters in the body
    const requestBody = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', authorizationCode)
      .set('redirect_uri', this._oicsRedirectUrl);

    this.httpClient.post<any>(oicsTokenUrl, requestBody, requestOptions)
    .subscribe(tokenResponse => {
      response.next(tokenResponse.id_token);
    },
    (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // client-side or network error
        console.error('An error occurred:', err.error.message);
      } else {
        // unsuccessful response codes (4XX, 5XX, etc)
        console.error('Backend returned status code: ', err.status);
        console.error('Response body:', err.error);
      }
    });

    return response.asObservable();
  }

  // get JWT token from local storage
  private getToken() {
    return localStorage.getItem(this.localStorageTokenKey);
  }

  // remove token from local storage and log user out of CloudPay
  public logout() {
    localStorage.removeItem(this.localStorageTokenKey);

    this.cloudPayService.logOut(true);
  }

  public myHoHLogin() {
    localStorage.setItem(this.localStorageLoginPath, window.location.href);
    window.location.href = this.oicsAuthorizeUrl;
  }

  // logout from MyHoH by redirecting to the logout URL
  public myHoHLogout() {
    let logoutUrl = new URL(`${this._oicsBaseUrl}/oauth2/v1/userlogout`);

    // add the token as a query parameter to the logout URL, if set
    const token = this.getToken();
    if (token) {
      logoutUrl.searchParams.set('post_logout_redirect_uri', this._oicsPostLogoutUrl);
      logoutUrl.searchParams.set('id_token_hint', token);
    }

    localStorage.setItem(this.localStorageLoginPath, window.location.href);
    window.location.href = logoutUrl.href;
  }
}
