<footer *ngIf="commonData?.footer">

  <app-footer-sponsors *ngIf="showSponsors && commonData.footer?.footer_sponsors" [footerSponsors]="commonData.footer.footer_sponsors"></app-footer-sponsors>

  <div id="footer-container">
    <div class="top">

      <div class="top-links" *ngIf="commonData.footer?.top_links">
        <div *ngFor="let item of commonData.footer?.top_links">

          <ng-container *ngIf="item.link | isExternalLink; else internal">
            <a class="external" [attr.href]="item.link" [attr.title]="item.link_title" target="_blank">
              <ng-container *ngTemplateOutlet="contentLink"></ng-container>
            </a>
          </ng-container>
          <ng-template #internal>
            <a routerLinkActive="selected" [routerLink]="item.link" [attr.title]="item.link_title">
              <ng-container *ngTemplateOutlet="contentLink"></ng-container>
            </a>
          </ng-template>

          <ng-template #contentLink>
            <amg-image *ngIf="item.image; else linkText" [src]="item.image.sizes.thumbnail.url" [title]="item.title" [alt]="item.title"></amg-image>
            <ng-template #linkText>
              {{item.title}}
            </ng-template>
          </ng-template>
        </div>
      </div>


      <div class="social-links" *ngIf="commonData.footer?.social_links">
        <div *ngFor="let item of commonData.footer.social_links">
          <a [attr.href]="item.link" target="_blank">
            <i [class]="'icon-' + social(item.link)"></i>
            <span [textContent]="social(item.link)"></span>
          </a>
        </div>
      </div>
    </div>


    <div class="links" *ngIf="commonData.footer?.links">
      <app-nav-links [links]="commonData.footer.links"></app-nav-links>
    </div>


    <div class="bottom">
      <app-nav-links *ngIf="commonData.footer?.bottom_links" [links]="commonData.footer.bottom_links"></app-nav-links>
      <div class="copyright" [textContent]="commonData.footer.copyright"></div>
    </div>
  </div>
</footer>
