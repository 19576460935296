<ng-template #content>
  <i *ngIf="pages[page].icon || iconClassName"
     class="icon icon-{{pages[page].icon}} {{pages[page].iconClassName}} {{iconClassName}}"></i>
  <span [textContent]="label ? label : (pages[page].label | label)"></span>
</ng-template>

<a *ngIf="pages[page].isExternal; else internalLink" [class]="className"
   [ngClass]="pages[page].className" [href]="pages[page].path">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #internalLink>
  <a [ngClass]="pages[page].className" [class]="className"
     [routerLink]="pages[page].isExternal === false ? pages[page].path : []">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>
