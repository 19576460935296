<div class="sponsors-container" *ngFor="let item of footerSponsors" [style.display]="!!item.sponsors ? '' : 'none'">
  <h2 class="title" [textContent]="item.title"></h2>

  <div class="footer-sponsors-container">
    <ng-container *ngFor="let sponsor of item.sponsors">

      <div *ngIf="sponsor.image" [class.show-border]="sponsor.show_border">
        <a [href]="sponsor.link" target="_blank">
          <amg-image [src]="sponsor.image?.sizes?.thumbnail.url"></amg-image>
        </a>
      </div>
    </ng-container>
  </div>

</div>
