import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { UserAuthMyHoHService } from '../user-auth-myhoh.service';

@Component({
  selector: 'app-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBtnComponent implements OnInit {
  @Input() label: string;
  @Input() showIcon: boolean;
  @Input() isButton: boolean;

  constructor(
    private userAuthMyHoHService: UserAuthMyHoHService
  ) {
  }

  ngOnInit(): void {
  }

  public doLogin(event) {
    event.preventDefault();
    this.userAuthMyHoHService.myHoHLogin();
  }
}
