import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoBannerComponent} from './info-banner.component';
import {SharedModule} from '../../core/shared.module';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [InfoBannerComponent],
  exports: [
    InfoBannerComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule
    ]
})
export class InfoBannerModule {
}
