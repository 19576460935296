import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {configs} from '../../../core/configs';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLinkComponent implements OnInit {
  @Input() page: 'subscribe' | 'register' | 'myAccount';
  @Input() iconClassName: string;
  @Input() label: string;
  @Input() className: string;

  public pages = {
    subscribe: {
      isExternal: false,
      className: '',
      label: 'subscribe',
      icon: 'subscribe',
      iconClassName: null,
      path: '/subscribe'
    },
    register: {
      isExternal: true,
      className: '',
      label: 'register',
      icon: null,
      iconClassName: null,
      path: environment.myHoH.baseUrl + configs.myHoH.urls.registration
    },
    myAccount: {
      isExternal: true,
      className: '',
      label: 'my_account',
      icon: null,
      iconClassName: null,
      path: environment.myHoH.baseUrl + configs.myHoH.urls.myAccount
    }
  };


  constructor() {
  }

  ngOnInit(): void {
  }

}
