import { CloudPayService } from '@streamamg/cloud-pay-lib/src/lib/cloud-pay.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { SubSink } from 'subsink';
import { WordpressService } from '../../services/wordpress.service';
import { WpCommonData } from '../../types/wordpress/common-data.type';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isLoggedIn: boolean;
  public menuOpened: boolean;
  public searchOpened: boolean;

  // public isLoggedIn: boolean;
  private subSink: SubSink;
  public commonData: WpCommonData;
  public formSearch: UntypedFormGroup = new UntypedFormGroup({
    q: new UntypedFormControl(''),
  });

  constructor(
    private router: Router,
    private wordpressService: WordpressService,
    private cloudPayService: CloudPayService,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platform: any,
  ) {

    this.subSink = new SubSink();
    this.menuOpened = false;
    this.searchOpened = false;
  }

  ngOnInit(): void {

    this.subSink.add(this.cloudPayService.cloudPayState()
    .subscribe(cloudPayState => {
      if (cloudPayState) {
        this.isLoggedIn = cloudPayState.isLoggedIn;
        this.cd.markForCheck();
      }
    }));

    this.subSink.add(this.wordpressService.getCommonData<WpCommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      })
    );

    this.subSink.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      })
    );
  }


  public openMenu() {
    this.menuOpened = true;


    window.scrollTo(0, 0);


    this.toggleOverlayClass();
    // trigger refresh html...
    this.cd.markForCheck();
  }

  public closeMenu() {
    this.menuOpened = false;

    this.toggleOverlayClass(true);
    // trigger refresh html...
    this.cd.markForCheck();
  }

  private toggleOverlayClass(remove?: boolean): void {

    if (remove) {

      this.renderer.removeClass(document.body.parentElement, 'overlay-opened');

      // setTimeout(() => {
      //   this.renderer.removeClass(document.body.parentElement, 'overlay-opened');
      // }, 700);
    } else {

      this.renderer.addClass(document.body.parentElement, 'overlay-opened');
    }
  }

  public searchToggle(inputElement?: HTMLInputElement) {

    this.toggleOverlayClass(this.searchOpened);
    this.searchOpened = !this.searchOpened;

    if (this.searchOpened && inputElement) {
      // after css transition is terminated
      setTimeout(() => {
        inputElement.focus();
      }, 700);
    }


    this.cd.markForCheck();
  }

  public submitSearch(event: Event, inputElement: HTMLInputElement): void {
    event.preventDefault();
    const filters = {...this.formSearch.value};
    Object.keys(filters).forEach((key) => (!filters[key] || filters[key] === '') && delete filters[key]);

    this.router.navigate(['hand-ball-match'], {queryParams: {...filters}});
    this.searchToggle();
    this.formSearch.reset();
    inputElement.blur();
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
