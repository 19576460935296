import { VideoEntitlementEnum } from '@amg-fe-angular/types/anywhere/anywhere.type';

export const configs = {

  carouselItemSize: 25,
  playlistPageSize: 50,
  generalContentPageSize: 24,
  fixturesContentPageSize: {
    default: 24,
    uhd: 25,
  },
  browseContentPageSize: {
    default: 24,
    fhd: 25,
    qhd: 30,
    uhd: 35,
  },

  streamPlayPartnerID: 3001394,

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001394',
    uiConfId: '30027935', // v2.55
    // uiConfId: '30030199', // v2.86.2
    //uiConfId: '30031236', // v2.55 longitude
    flashvars: {
      'playerSeekButtonAMG.plugin': true,
      'playerSeekButtonAMG.parent': 'videoHolder',
      'playerSeekButtonAMG.seekValue': 15,
      'currentTimeLabel.order': 10,
      'liveStatus.order': 11,
      'durationLabel.order': 12,
      'playPauseBtn.order': 40,
      'volumeControl.order': 100,
      'audioSelector.order': 101,
      'sourceSelector.order': 80,
      'chromecast.order': 70,
      'fullScreenBtn.order': 50,

      // 'fullScreenBtn.parent': 'videoHolder',
      // 'volumeControl.showSlider': false,
      IframeCustomPluginCss1: '/video-player.css',
      // hide DoubleClick due to requirement SWD-660
      // doubleClick: {adTagUrl: 'https://pubads.g.doubleclick.net/gampad/live/ads'},
      // iuValue: '/67970281,22659771201/VIDEO_THIRDPARTY_GBL/EHFTV/',
    },
    multicam: {
      flashvars: {
        IframeCustomPluginCss1: '/multicam-player.css',
        IframeCustomPluginJs1: '/assets/js/kaltura-multicam.js',
      },
    },
  },

  cloudMatrix: {
    feed: 'https://ehf-cm.streamamg.com/api/v1/cb6b9d25-5ab3-467a-a5b7-a662b0ddcb3d/BH9t1g7ARQzD2TgcHm9ZosHSES3ADnSpMv60i4pfRqtcti2MeM/30b60d3d-7c68-4215-8b5c-b68ef503980f/en/feed/a9364863-def1-4be6-83c1-c91a632b66a4/sections/?section=3002115e-7aa8-4ecb-8485-4146612af205',
    defaultEntitlement: VideoEntitlementEnum['*'], //  registered
  },

  sso: {
    urls: {
      baseUrl: 'https://idcs-76d0c47b545a40a9b9d44719568f5e3e.identity.oraclecloud.com',
    },
  },
  myHoH: {
    urls: {
      myAccount: '/my-profile',
      registration: '/registration',
    },
  },

  wordpress: {
    pages: {
      home: '/page?slug=home',
      schedules: '/page?slug=schedules',
      archive: '/page?slug=archive',
      player: '/page?slug=player',
      previewMatch: '/page?slug=preview-match',
      editorial: '/page',
    },
    competition: '/competition',
    playlist: '/playlist',
    campaign: '/campaign',
    commonData: '/common_field?slug=common-fields',
    category: '/category',
  },

  longitude: {
    scriptPlayerPage: 'https://lngtd.com/ehftv_player.js',
    scriptROS: 'https://lngtd.com/ehftv_ros.js',
  },
};
