import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {WpNavLink} from '../../types/wordpress/common-data.type';
import {configs} from '../../configs';
import {WpCompetition, WpCompetitionMap} from '../../types/wordpress/competition.type';
import {WordpressService} from '../../services/wordpress.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-nav-links',
  templateUrl: './nav-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavLinksComponent implements OnInit, OnDestroy {
  @Input() links: WpNavLink[];
  @Input() subMenuAccordion: boolean;
  private subSink: SubSink;
  public competitions: WpCompetition[];
  public competitionSubMenuMobileHeight: string;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef,
  ) {

    this.subSink = new SubSink();
    this.competitionSubMenuMobileHeight = 'auto';
  }

  ngOnInit(): void {


    this.subSink.add(this.wordpressService.getPageData(configs.wordpress.competition)
      .subscribe((data: WpCompetitionMap) => {
        if (data && Object.keys(data).length) {
          this.competitions = Object.values(JSON.parse(JSON.stringify(data)));

          if (this.subMenuAccordion) {
            this.competitionSubMenuMobileHeight = `${(this.competitions.length * 46) + 10}px`;
          }

          this.cd.markForCheck();
        }
      })
    );
  }

  public toggleSubmenu(event: Event, item: WpNavLink): void {

    event.preventDefault();

    item.submenuOpened = !item.submenuOpened;
    this.cd.detectChanges();
  }


  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
