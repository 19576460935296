import { InfoBannerModule } from './../info-banner/info-banner.module';
import { NgModule } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LoginBtnComponent } from './login-btn/login-btn.component';
import { LogoutBtnComponent } from './logout-btn/logout-btn.component';
import { UserLinkComponent } from './user-link/user-link.component';
import { SharedModule } from '../../core/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LoginBtnComponent,
    UserLinkComponent,
    LogoutBtnComponent,
  ],
  providers: [
    Location
  ],
  exports: [
    LoginBtnComponent,
    UserLinkComponent,
    LogoutBtnComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    InfoBannerModule,
    ReactiveFormsModule,
    RouterModule
  ]
})
export class UserAuthModule {
}
