import { SubSink } from 'subsink';
import { WpCommonData } from './../../core/types/wordpress/common-data.type';
import { WordpressService } from './../../core/services/wordpress.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html'
})
export class InfoBannerComponent implements OnInit, OnDestroy {
  private subSink: SubSink;
  public isLoading: boolean;
  public infoBannerContent: string;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef
  ) {
    this.isLoading = true;
    this.subSink = new SubSink();
  }

  ngOnInit(): void {
    this.subSink.add(this.wordpressService.getCommonData<WpCommonData>()
      .subscribe(data => {
        if (data) {
          this.infoBannerContent = data.labels['info_banner'];
          this.isLoading = false;
          this.cd.markForCheck();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}
