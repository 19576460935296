import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterSponsorsComponent } from './footer-sponsors.component';
import {SharedModule} from '../../core/shared.module';



@NgModule({
    declarations: [FooterSponsorsComponent],
    exports: [
        FooterSponsorsComponent
    ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class FooterSponsorsModule { }
