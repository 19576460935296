<nav>
  <ng-container *ngFor="let item of links">

    <div *ngIf="item.link === '/hand-ball-competitions' && competitions; else link" class="submenu"
         [class.closed]="!item.submenuOpened">
            <span class="label-group" (click)="toggleSubmenu($event, item)">
              <span routerLinkActive="selected" [routerLink]="'/hand-ball-competitions'"
                [attr.title]="item.link_title" [textContent]="item.title"></span>
              <i *ngIf="subMenuAccordion" class="icon-arrow-dropdown"></i>
            </span>

      <div class="submenu-content">
        <ul [ngStyle]="{ height: competitionSubMenuMobileHeight }">
          <li *ngFor="let item of competitions">
            <a routerLinkActive="selected" [routerLink]="'/hand-ball-competitions/' + item.post_slug"
              [attr.title]="item.nav_link_title" [textContent]="item.sponsor_name || item.post_title"></a>
          </li>
        </ul>
      </div>
    </div>
    <ng-template #link>
      <div *ngIf="item.title && item.link">
        <ng-container *ngIf="item.link | isExternalLink; else internal">
          <a class="external" [attr.href]="item.link" [attr.title]="item.link_title" target="_blank" [textContent]="item.title"></a>
        </ng-container>
        <ng-template #internal>
          <a routerLinkActive="selected" [routerLink]="item.link" [attr.title]="item.link_title" [textContent]="item.title"></a>
        </ng-template>
      </div>
    </ng-template>
  </ng-container>
</nav>
