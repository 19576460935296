import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { UserAuthMyHoHService } from '../user-auth-myhoh.service';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutBtnComponent implements OnInit {

  constructor(
    public userAuthMyHoHService: UserAuthMyHoHService
  ) {
  }

  ngOnInit(): void {
  }

  public doLogout(event) {
    event.preventDefault();
    this.userAuthMyHoHService.myHoHLogout();
  }
}
