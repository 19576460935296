import { WordpressService as WordpressServiceAmg } from '@amg-fe-angular/services/wordpress.service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';

import { WpCloudMatrixFilter, WpPageContent } from '../../modules/page-content/page-content.type';
import { environment } from '../../../environments/environment';
import { configs } from '../configs';
import { WpClub, WpCommonData } from '../types/wordpress/common-data.type';
import { WpCampaignData, WpCompetition, WpCompetitionMap } from '../types/wordpress/competition.type';
import { WpCategoryMap } from '../types/wordpress/categories.type';

@Injectable({
  providedIn: 'root'
})
export class WordpressService extends WordpressServiceAmg {

  private wpCategoryMap: WpCategoryMap;
  private wpCompetitionMap: WpCompetitionMap;

  constructor(httpClient: HttpClient, @Inject(PLATFORM_ID) platform: any) {
    super(httpClient, platform);

    super.init(
      environment.wordpress.api,
      `${environment.wordpress.api}${configs.wordpress.commonData}`
    );
  }

  /**
   * Grabs the data from the memory cache if available, else it will trigger a call and retrieves the data.
   * @param pagePath WP page path
   */
  public override getPageData<D>(pagePath: string): Observable<D> {
    if (pagePath.indexOf(configs.wordpress.pages.editorial) === -1) {
      return this.getData<D>(this.generatePageUrl(pagePath));
    }

    const o = new BehaviorSubject<D>(null);

    combineLatest([
      this.getCategories(),
      this.getCompetitions(),
      this.getData<D>(this.generatePageUrl(pagePath)) as Observable<any>,
    ])
      .subscribe(([wpCategoryMap, wpCompetitionMap, data]) => {
        if (!this.wpCategoryMap && !this.wpCompetitionMap) {
          this.wpCategoryMap = this.mapData<WpCategoryMap>(wpCategoryMap);
          this.wpCompetitionMap = this.mapData<WpCompetitionMap>(wpCompetitionMap);
        }

        if (data.page_content && data.page_content['pristine'] !== 'dirty') {
          (data.page_content as Array<WpPageContent>)
            .forEach((wpPageContent) => {
              if (wpPageContent.type === 'carousel-cloud-matrix') {
                const cloudMatrixFilters = wpPageContent.cloud_matrix_filters;
                cloudMatrixFilters.categories = this.patchCategoryValue(cloudMatrixFilters.categories);
                cloudMatrixFilters.competitions = this.patchCompetitionValue(cloudMatrixFilters.competitions);
                cloudMatrixFilters.teams = this.patchClubValue(cloudMatrixFilters.teams);
              }
            });
          data.page_content['pristine'] = 'dirty';
        }

        o.next(data);
      });

    return o.asObservable();
  }

  /**
   * Grabs the competition data from the memory cache if available, else it will trigger a call and retrieves the data.
   */
  public getCompetitions(): Observable<WpCompetitionMap> {

    return this
      .getData<WpCompetitionMap>(this.generatePageUrl(configs.wordpress.competition));
  }

  /**
   * Grabs the category data from the memory cache if available, else it will trigger a call and retrieves the data.
   */
  public getCategories(): Observable<WpCategoryMap> {

    return this
      .getData<WpCategoryMap>(this.generatePageUrl(configs.wordpress.category));
  }

  public getCompetitionByProperty(property: string, value: string | boolean): Observable<WpCompetition> {

    const replaySubject = new ReplaySubject<WpCompetition>(1);

    super.getPageData(configs.wordpress.competition).subscribe((competitions: WpCompetitionMap) => {


      const competitionSelected = Object.values(competitions).find(competition => {

        return competition[property] === value;

      });

      replaySubject.next(competitionSelected);

    });

    return replaySubject.asObservable();
  }

  public getClubByProperty(property: string, value: string | boolean): Observable<WpClub> {

    const replaySubject = new ReplaySubject<WpClub>(1);

    super.getCommonData<WpCommonData>().subscribe((commonData) => {

      replaySubject.next(commonData.clubs.find(club => club[property] === value));

    });

    return replaySubject.asObservable();
  }

  public getCampaignBySlug(slug: string): Observable<WpCampaignData> {
    const replaySubject = new ReplaySubject();

    super.getPageData(`${configs.wordpress.campaign}?slug=${slug}`).subscribe((campaign: WpCampaignData) => {
      replaySubject.next(campaign);
    });

    return replaySubject.asObservable();
  }

  private mapData<T>(data: T): T {
    const toReturn = {...data};

    Object
      .keys(data)
      .forEach(key1 => {
        const value = data[key1];
        const key2 = value.post_id;
        toReturn[key2] = value;
      });

    return toReturn;
  }

  private patchCategoryValue(category: any): Array<WpCloudMatrixFilter> {
    if (category?.length > 0) {
      const categories = [];
      category
        .forEach((id) => {
          const newCategory = this.wpCategoryMap[id];

          categories.push({
            disable: false,
            value: this.cleanPostName(newCategory.post_title),
          });
        });

      category = categories;
    }

    return category;
  }

  private patchCompetitionValue(competition: any): Array<WpCloudMatrixFilter> {
    if (competition?.length > 0) {
      const competitions = [];
      competition
        .forEach((id) => {
          const newCompetition = this.wpCompetitionMap[id];

          competitions.push({
            disable: false,
            value: this.cleanPostName(newCompetition.post_title),
          });
        });

      competition = competitions;
    }

    return competition;
  }

  private patchClubValue(club: any): Array<WpCloudMatrixFilter> {
    club = club.split(',')
      .filter(c => c.length > 0)
      .map((c) => {
        return {
          disable: false,
          value: this.cleanPostName(c),
        };
      });

    return club.length > 0
      ? club
      : undefined;
  }

  private cleanPostName(s: string): string {
    return s.trim();
  }

}
