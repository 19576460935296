<header [class.menu-opened]="menuOpened" [class.search-opened]="searchOpened">
  <div class="logo">
    <a routerLink="/home"></a>
  </div>

  <div (click)="closeMenu()" class="close-menu-overlay"></div>
  <div id="header-menu" class="mobile-overlay">
    <div (click)="closeMenu()" class="close-btn icon-cross"></div>
    <div id="header-menu-content">

      <app-nav-links [links]="commonData.header?.menu" [subMenuAccordion]="true"></app-nav-links>

      <div class="secondary-menu">

        <ng-container *ngIf="isLoggedIn; else notLogged">
          <div class="my-account">
            <a [textContent]="'my_account' | label" role="button" tabIndex="0"></a>
           <!-- <app-user-link page="myAccount"></app-user-link>-->
            <div class="menu">
              <app-user-link class="small-screen" page="myAccount"></app-user-link>
              <app-user-link class="large-screen" page="myAccount" [label]="'my_profile' | label"></app-user-link>
              <app-logout-btn></app-logout-btn>
            </div>
          </div>
        </ng-container>
        <ng-template #notLogged>
          <div>
            <app-login-btn [showIcon]="true" label="{{'login' | label}}<span class='hide-desktop'> / {{'register' | label}}</span>"></app-login-btn>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div id="header-search-container" class="mobile-overlay">
    <div (click)="searchToggle(searchInput)" class="close-btn icon-cross"></div>
    <div class="search-content">
      <label class="search-lbl" [textContent]="'search' | label"></label>
      <div class="field-container">
        <form [formGroup]="formSearch" (ngSubmit)="submitSearch($event, searchInput)">
          <button type="submit" class="icon-search"></button>
          <input #searchInput type="text" formControlName="q" [placeholder]="'search.placeholder' | label">
          <div (click)="searchToggle()" class="close-btn icon-cross"></div>
        </form>
      </div>
    </div>
  </div>
  <div class="search-toggle" (click)="searchToggle(searchInput)">
    <i class="icon-search"></i>
  </div>

  <div (click)="openMenu()" class="menu-mobile-icon">
    <div></div>
    <div></div>
    <div></div>
  </div>

</header>
