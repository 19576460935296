import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'handball-europe-competitions-schedules',
    redirectTo: 'handball-competitions-schedules'
  },
  {
    path: 'competitions',
    redirectTo: 'hand-ball-competitions'
  },
  {
    path: 'archive',
    redirectTo: 'hand-ball-match'
  },
  {
    path: 'faqs-handball-frequently-asked-questions',
    redirectTo: 'handball-europe-competitions-faqs-ehf'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule)
  },
  {
    path: 'handball-competitions-schedules',
    loadChildren: () => import('./pages/schedules/schedules.module')
      .then(m => m.SchedulesModule)
  },
  {
    path: 'hand-ball-match',
    loadChildren: () => import('./pages/archive/archive.module')
      .then(m => m.ArchiveModule)
  },
  {
    path: 'hand-ball-competitions',
    loadChildren: () => import('./pages/competition/competition.module')
      .then(m => m.CompetitionModule)
  },
  {
    path: 'player/vod',
    loadChildren: () => import('./pages/player/vod/vod.module')
      .then(m => m.VodModule)
  },
  {
    path: 'handball-live-stream',
    loadChildren: () => import('./pages/player/live/live.module')
      .then(m => m.LiveModule)
  },
  {
    path: 'handball-match/multicam',
    loadChildren: () => import('./pages/player/multicam/multicam.module')
      .then(m => m.MulticamModule)
  },
  {
    path: 'campaign',
    loadChildren: () => import('./pages/campaign/campaign.module')
      .then(m => m.CampaignModule)
  },
  {
    path: '**',
    loadChildren: () => import('./pages/editorial/editorial.module')
      .then(m => m.EditorialModule)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})], // scrollPositionRestoration: 'enabled'
  exports: [RouterModule],
})
export class AppRoutingModule {
}
